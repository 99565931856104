import {
  selectContractSchema,
  selectDepartmentSchema,
  selectUserSchema
} from '@repo/db/schema'
import { z } from 'zod'
import type { searchContracts } from './searchContracts.server'

export type ContractSearchResult = Awaited<
  ReturnType<typeof searchContracts>
>[number]

export const UpdateOwnerSchema = z.object({
  intent: z.literal('updateOwner'),
  ownerId: selectUserSchema.shape.id,
  contractId: selectContractSchema.shape.id
})

export const UpdateDepartmentSchema = z.object({
  intent: z.literal('updateDepartment'),
  departmentId: selectDepartmentSchema.shape.id,
  contractId: selectContractSchema.shape.id
})

export const SearchContractsSchema = z.object({
  intent: z.literal('searchContracts'),
  query: z.string()
})
